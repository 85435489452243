.logo {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}

.logo p {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    padding-left: 10px;
}

.logo p span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
}

.mainMenu {
    background-color: #10253A !important;
    height: 55px;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
}

.navbar {
    display: flex;
    align-items: center;

}

.ui.menu:after {
    content: none;
}



.navbar a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    padding-left: 5px;
}

.headerItem {
    width: 11% !important;
    border-right: 1px solid #123252 !important;
    color: white;
    display: flex;
    justify-content: space-evenly;
}

.logout {
    border-left: 1px solid #123252 !important;
    width: 8% !important;
    justify-content: center;

}