.moments-list-card-bottom-content {
  display: flex;
  flex-direction: column;
}

.moments-list-card-bottom-content .add-moment-button {
  align-self: flex-end;
}

.moments-list-moment-card-image-container {
  min-height: 296px;
}

.moments-list-moment-card-image-container img {
  width: 100%;
  height: 100%;
  min-height: 296px;
  object-fit: cover;
}

.user-details-form_images-list .image-container {
  width: 100%;
  height: 290px;
  overflow: hidden;
}

.user-details-form_images-list .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
