.image-container {
  position: relative;
}

.user-ui-two-buttons-add-moment-button button {
  min-width: 100%;
}

.user-card-main-container {
  display: flex !important;
  /* align-items: center; */
  justify-content: center !important;
}

.ui.card:after {
  display: none !important;
}

.ui.card > .content,
.ui.cards > .card > .content {
  display: flex;
  align-items: center;
}

.user-details-form_images-list .image-container {
  width: 100%;
  height: 290px;
  overflow: hidden;
}

.user-details-form_images-list .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.searchBox input {
  border-radius: 20px !important;
  height: 40px !important;
  background-color: #f5f5f5 !important;
}

.ui.labeled.icon.button,
.ui.labeled.icon.buttons .button {
  padding-left: 1.5em !important;
  padding-right: 4em !important;
}

.sortBox i {
  right: 0 !important;
  left: 74% !important;
  background-color: #ffffff !important;
  border-left: 0.5px solid #f2f2f2;
}

.sortBox.button {
  background: #ffffff !important;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15) !important;
  color: #6f6e6e !important;
  font-size: 12px;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  border-radius: 8px;
  height: 35px;
}

.sortBox {
  position: relative !important;
}

.sortBox img {
}

.iconBox {
  position: absolute;
  right: 0%;
  bottom: 0px;
  padding: 14px;
  height: 35px;
  border-left: 0.5px solid #f2f2f2;
}

.userText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  margin: 10px 0px 5px 0px !important;
  color: #333333;
}

.pageText {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #6f6e6e;
}

.cardHeader {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
}

.cardHeader p {
  margin-bottom: 0 !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
}

.pagination {
  display: flex;
}

.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}

.pagination > .active > a {
  background-color: #5647de;
  border-color: #5647de;
  color: #fff;
}

.pagination > li > a {
  border: 1px solid #dfe3e8;
  border-radius: 4px !important;
  padding: 5px 10px;
  outline: none;
  cursor: pointer;
}

.pagination > li {
  margin: 0 5px;
  border-radius: 4px !important;
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: white;
  border-color: #21afdc;
  outline: none;
  color: #21afdc;
}

.pagination > li > a,
.pagination > li > span {
  color: #212b36;
  font-weight: 700;
  font-size: 14px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: unset;
}

.disableLink {
  background-color: #919eab;
  background: #919eab;
  color: #c4cdd5 !important;
  border: 1px solid #919eab !important;
  border-radius: 4px !important;
}

.previousLink {
  border-radius: 4px !important;
  color: #c4cdd5 !important;
}

.nextLink {
  border-radius: 4px !important;
  color: #c4cdd5 !important;
}

.filterBox {
  border-radius: 20px !important;
  background-color: #ffffff !important;
  border: 1px solid #f2f2f2 !important;
  font-family: "Poppins" !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  padding-top: 11px !important;
  padding-bottom: 11px !important;
  color: #6f6e6e !important;
  margin-left: 10px !important;
}

.dropContainer p {
  margin-bottom: 0 !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #6f6e6e;
}

.filterBox .iconBox {
  border: none !important;
}

.filterMenu .row {
  align-items: center !important;
  padding: 8px 0px !important;
}

.dropdownView {
  width: 72%;
}

.filterMenu {
  padding: 10px 0px !important;
}

.ui.checkbox input:checked ~ .box:after,
.ui.checkbox input:checked ~ label:after {
  color: #ffffff !important;
  background-color: #2e7df4 !important;
}

/* .ui.checkbox input:checked~.box:after,
.ui.checkbox input:checked~label:after {
  content: '\e800' !important;
} */

/* .ui.checkbox .box:after,
.ui.checkbox label:after {
  top: 0 !important;
  left: 0 !important;
  width: 17px !important;
  height: 17px !important;
  font-size: 14px !important;
} */

.radioBox label:after {
  border-radius: 50% !important;
  border: none !important;
}

.radioBox label:before {
  border-radius: 50% !important;
}

.filterButton {
  color: #ffffff !important;
  background-color: #21ba45 !important;
  padding: 10px 40px !important;
  border-radius: 5px !important;
}

.ui.selection.dropdown {
  min-width: 9em;
}

.filterBox .menu,
.filterInput {
  width: 120px !important;
}

.country-dropdown .menu {
  width: 273px !important;
}

.filterInput > input {
  background-color: #ffffff !important;
  height: 35px !important;
  font-size: 11px;
}

.moderatorFilterInput {
  width: 165px !important;
}
