body {
  margin: 0;
  font-family:  'Inter', sans-serif;;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.pac-container:after{
  content:none !important;
}

#infowindow-content .title {
  font-weight: bold;
}

body iframe{
  width: 0 !important;
  height: 0 !important;
}