/* .userContainer {
    margin-top: 20px !important;
} */

.userInfo {
  margin-top: 20px !important;
}

.userCard {
  border-radius: 12px !important;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1) !important;
  height: 100% !important;
}

.userContent {
  border: none !important;
}

.userImage {
  background-color: white !important;
  padding: 15px;
}

.userImage img {
  border-radius: 8px;
  object-fit: cover;
}

.footer {
  justify-content: space-between !important;
  padding-top: 0 !important;
  border: none !important;
}

.ui.card > .content:after,
.ui.cards > .card > .content:after {
  content: none;
}

.description p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #6f6e6e;
  margin-bottom: 0 !important;
  line-height: 20px;
}

.footer p {
  margin-bottom: 0 !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
}

.actionView {
  position: absolute;
  top: 8% !important;
  right: 8% !important;
}

.chatIcon {
  padding-top: 8px;
}

.ui.card > .extra,
.ui.cards > .card > .extra {
  border: none !important;
}

.timer {
  position: absolute;
  right: 3em;
  top: -1em;
  z-index: 100;
  padding: 4px 16px;
  background: #b11226;
  color: #fff;
  /* border-radius: 8px; */
  /* animation: countdown 1s linear infinite alternate; */
}

.timer-container {
  display: flex;
  justify-content: center;
}

@keyframes countdown {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.75;
  }
}

.ui.tabular.menu .item:hover {
  color: #fff;
}

.ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}
.ui.table thead tr:first-child > th,
.ui.table tfoot tr:first-child > th {
  position: sticky !important;
  bottom: 0;
  z-index: 2;
}
