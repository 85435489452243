::-webkit-scrollbar {
  width: 4px;
  /* width of vertical scrollbar */
}

.closeButton {
  width: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
