.add-moment-card-container {
  overflow: hidden;
}

.create-fake-moderator-remove-moment-image-icon {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 0px !important;
}
