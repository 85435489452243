.main__container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: 80vw;
  max-height: 80vh;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 1px rgb(0 0 0 / 12%);
  z-index: 10;
  display: none;
  padding: 30px;
}

.main__container.open {
  display: block !important;
}

.image__cont {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  overflow-y: auto;
  height: 100%;
}

.image__cont img {
  flex: 0 1 calc(20% - 10px);
  object-fit: contain;
  height: 250px;
  width: calc(20% - 10px);
  border: 1px solid #000;
  cursor: pointer;
}

.vedio_cont video {
  flex: 0 1 calc(20% - 10px);
  object-fit: cover;
  cursor: pointer;
  border-radius: 15px;
}

.close__icon {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 20px;
  font-weight: bolder;
  cursor: pointer;
}