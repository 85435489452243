.dimmable .row [class*="right floated two wide"].column {
  width: 20.5% !important;
}

.dimmable .row [class*="six wide"].column {
  width: 37.5% !important;
}

.moments-list-card-bottom-content {
  display: flex;
  flex-direction: column;
}

.moments-list-card-bottom-content .add-moment-button {
  align-self: flex-end;
}

.moments-list-moment-card-image-container {
  min-height: 296px;
}

.moments-list-moment-card-image-container img {
  width: 100%;
  height: 100%;
  min-height: 296px;
  object-fit: cover;
}

.user-details-form_images-list .image-container {
  width: 100%;
  height: 290px;
  overflow: hidden;
}

.user-details-form_images-list .image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cardWrapper {
  overflow: hidden;
  border: 1px solid #eeeeee;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 11px;
}

.no-margin {
  margin: 0px;
}

.no-border {
  border: unset;
}

.card_header {
  width: 100%;
  background-color: #5fb084;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  padding: 10px;
}

.card_header.blue {
  background: #2e7df4;
  padding: 31.5px 85.5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.card_body {
  padding: 10px;
}

.card_body_heding {
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #3a3a3a;
  margin-bottom: 10px;
}

.card_body_description {
  color: #969696;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 8px !important;
}

.card_footer {
  background-color: #d9d9d9;
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.moderator_container {
  text-align: center;
  font-family: "Inter";
  /* margin: 12px 0; */
  line-height: unset;
  font-size: 0px;
}

.moderator_name {
  background: #faa930;
  padding: 30px 0;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  margin-bottom: 12px;
  font-family: "Inter";
}

.moderator_ {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #3a3a3a;
  margin-bottom: 12px;
}

.photos_container.ui.segment {
  padding: 0;
  border: none;
  box-shadow: none;
  margin-top: 0px;
  margin: 0;
}

.photos_container .ui.card {
  width: 100%;
  box-shadow: none;
  background: #eeeeee;
}

.photos_container .ui.segment {
  box-shadow: none;
}

.photos_container .ui.card > .extra {
  border-top: none !important;
}

.photos_container .ui.card > .content {
  border: none;
}

.photos_container .ui.card > .photos_content {
  display: block;
}

.photos_container .ui.card > .content > .photos_header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}

.card_name {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.tab_content {
  border-radius: 5px;
}

.gender_content {
  border-radius: 5px;
}

.tabContent .column {
  padding: 0 !important;
  padding-right: 0 !important;
}

.tabContent {
  margin: 0 !important;
}

.tab_content .tab_name:first-child {
  border-radius: 5px 0 0 5px;
}

.tab_content .tab_name:last-child {
  border-radius: 0 5px 5px 0;
}

.tab_name {
  background-color: #c6c6c6;
  padding: 6px 24px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #3a3a3a;
  cursor: pointer;
}

.tab_name.active {
  background: #54de9c;
}

.slider_content {
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
}

.dropdown_container {
  margin: 0 0 10px 0 !important;
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
}

.dropdown_content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.dropdown_content .User_Info {
  color: #171b1b;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.story_user_content {
  display: flex;
  justify-content: space-between;
  background-color: #c6c6c6;
  border-radius: 5px;
  padding: 12px 10px;
  margin: 0 10px;
}

.story_user_content .story_user,
.story_user_content .story_user_view {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #3a3a3a;
}

.story_user_content .story_user_view {
  color: #000000;
  font-weight: 500;
}

.ui.header.story_header {
  margin: 0 0 10px 0;
}

.user_photos_container {
  margin: 0 0 10px 0 !important;
}

.user_photos_container .content.photos_content {
  padding: 0 11px;
}

.ui.form .Note_msg {
  height: 101px;
  border: none;
  background-color: #fff;
  resize: none;
  border-radius: 5px 5px 0 0;
}

.ui.blue.button.add_note_btn {
  border-radius: 0 0 5px 5px;
  background: #21dc77;
  width: 100%;
  padding: 12px 0;
}

.UserDropdown {
  width: 100%;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  align-items: center;
  display: flex !important;
  margin-bottom: 5px;
  font-weight: 700;
  padding: 0px 10px;
}

.UserDropdown .divider {
  width: 100%;
}

.UserDropdown i.icon {
  font-size: 1.5em;
}

.userInfo .ui.dropdown > .dropdown.icon {
  font-size: 20px;
  top: 2px;
  right: 8px;
  color: #c6c6c6 !important;
}

.UserStory {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 10px;
  border: 5px;
  background: #c6c6c6;
  border-radius: 5px;
  margin-top: 10px;
}

.UserStory .story {
  display: inline-block;
  font-weight: 700;
  font-family: "Inter";
  color: #3a3a3a;
  font-size: 14px;
}

.UserStory .view {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  font-family: "Inter";
}

.slider_content img {
  margin-top: 10px;
  max-height: 120px;
}

.UserMoment {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 10px;
  border: 5px;
  background: #c6c6c6;
  border-radius: 5px;
  margin-top: 10px;
}

.UserMoment .moment {
  display: inline-block;
  font-weight: 700;
  font-family: "Inter";
  color: #000000;
  font-size: 14px;
}

.UserMoment .view1 {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  font-family: "Inter";
}

.textbox {
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
}

.textbox textarea {
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  width: 100%;
  min-height: 62px !important;
  height: 62px !important;
  padding: 10px;
  resize: none;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #969696;
}

.noteBox textarea {
  min-height: 70px !important;
  height: 70px !important;
}

.noteBox {
  margin-top: 0 !important;
}

.textbox button {
  background: #21dc77;
  padding: 12px 0;
  border: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
  width: 100%;
  font-family: "Inter";
}

.fieldsUser label {
  padding: 12px 0;
  margin-bottom: 10px;
  font-family: "Inter";
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 17px;
  color: #171b1b !important;
  /* width: 100%; */
}

.fieldsUser .field {
  margin-top: 10px !important;
  padding-right: 0px !important;
  /* width: 100%; */
}

.fieldsUser .field label {
  padding-top: 0px !important;
  /* width: 100%; */
}

.fieldsUser {
  margin-right: 0px !important;
  margin-bottom: 5px;
  margin-top: 5px;
}
.showOption .ui.dropdown>.dropdown.icon, .showOption i.delete.icon{
  display: none;
}
.fieldsUser.flexView button {
  width: 100px;
  margin-left: 10px;
  height: 33px;
  margin-top: 5px;
}
.fieldsUser.flexView {
  display: flex;
}
.moderat {
  width: 100%;
  height: 30px;
  padding: 11.5px 113px;
  border: none;
  font-size: 11px;
  line-height: 5px;
  text-align: center;
  background: #ffffff;
  justify-content: center;
}

.samedave {
  width: 100%;
  height: 63px;
  border: none;
  font-size: 13px;
  line-height: 17px;
  font-weight: 700;
  color: #ffffff;
  background: #faa930;
  display: flex;
}

.title_div{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
  font-size: 13px;
  background-color: #b11226;
  text-align: center;
}

.name_div{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  font-size: 13px;
  text-align: center;
  padding-left: 10px;
}

.yannmartin {
  width: 100%;
  height: 63px;
  padding: 22.5px 121px;
  border: none;
  font-size: 13px;
  line-height: 17px;
  font-weight: bold;
  color: #ffffff;
  background: #2e7df4;
  text-align: center;
}

.headeruser {
  width: 100%;
  height: 30px;
  padding: 11.5px 113px;
  border: none;
  font-size: 11px;
  line-height: 5px;
  text-align: center;
  background: #f0f0f0;
}

.User_info_btn {
  /* line-height: 4.0em !important; */
  padding-top: 15px;
  padding-bottom: 5px;
}

.active_btn,
.Hobbies_btn,
.Address_btn {
  background: #eeeeee !important;
  padding: 10px 9px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #969696 !important;
  cursor: pointer;
  width: 99%;
}

.male_btn,
.other_btn,
.female_btn {
  background: #eeeeee !important;
  padding: 10px 2px !important;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: #969696 !important;
  cursor: pointer;
  border-radius: 5px !important;
  width: 95%;
  /* margin-right: 10px !important; */
}

.gender_active {
  background: #79be80 !important;
  color: #ffff !important;
  padding: 6px 2px;
  font-weight: 700;
}

/* .femaleBtn{
  margin-left: 2px !important;
  margin-right: 2px !important;
  padding: 6px 3px;

} */

.Personal_active {
  background: #79be80 !important;
  color: #ffff !important;
  padding: 6px 19px;
  font-weight: 700;
}

.userInfo .ui.segment {
  border: 0px !important;
}

.rightSide {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin-top: 20px !important;
}

.leftSide {
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
  border-top-right-radius: 15px !important;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin-top: 20px !important;
}

.messageBox textarea {
  width: 100%;
  border: 0px;
  height: 120px;
  outline: none;
}

.messageBox {
  width: 100% !important;
  max-height: 215px;
  padding: 15px !important;
  border: 0px !important;
  margin: 0 !important;
}

/* .ui.top.popup:before {
  background: #E2B747 !important;
} */
.messagePopUp:before {
  background: #e2b747 !important;
}

.sendGift {
  background: #cc8ae3 !important;
  color: white !important;
  width: 100% !important;
  height: 42px !important;
  z-index: 1 !important;
  opacity: 1 !important;
}
.SendGitImgageSelected {
  opacity: 1 !important;
  z-index: 1500 !important;
  margin-top: 3.5vh;
  position: relative;
}
.send {
  background: #21dc77 !important;
  color: white !important;
  width: 100% !important;
  height: 42px !important;
}

.shareLocation {
  background: #79be80 !important;
  color: white !important;
  width: 100% !important;
  height: 42px !important;
}

.modals {
  z-index: 9999 !important;
}

.storyActive {
  background: #54de9c !important;
  color: white !important;
}

.storyActive .count {
  border-radius: 50% !important;
  padding: 3px 7px !important;
  background: white !important;
  color: #54de9c !important;
  text-align: center !important;
}

.storyButton {
  width: 100% !important;
}

.closeButton {
  width: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.commentBox textarea {
  border: none;
  width: 100% !important;
  height: 80px;
  outline: none;
}

.giftDimmer {
  opacity: 0.6 !important;
  background-color: #000000;
}
