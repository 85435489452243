.parent {
    width: 100%;
    overflow: hidden;
    margin: 0;
    /* padding: 1rem; */
  }
  .slider-container {
   /* if you want to have a scrollbar, you can add overflow here */ 
    overflow-x: hidden;
    display: flex;
    align-items: center;
    margin: 0rem;
    /* padding: 3px; */
   scroll-behavior: smooth;
   /* position: relative; */
  }
  .slider-wrapper {
    display: flex;
    align-items: center;
    /* width: 100%; */
  }
  .btn {
    position: absolute;
    /* box-shadow: 0 0 6.1px 0.9px grey; */
    /* padding: 5px; */
    cursor: pointer;
    width: 20px;
    height: 20px;
    height: 56%;
    background: white;
    opacity: 0.7;
    text-align: center;
    color: white;
    /* bottom: 0px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    /* border-radius: 50%; */
    
  }
  .prev {
    left: 1rem;
  }
  .next {
    right: 1rem;
  }
  .disable {
    opacity: 0.5;
   pointer-events: none;
  }
  .child {
    /* padding: 5px 20px; */
    text-align: center;
    width: 100%;
    margin-right: 10px;
    border-radius: 21px;
  }

  .slider_content {
    display: flex;
    justify-content: space-between;
    
  }
  
  .slider_content img {
    margin-top: 10px;
    margin-right: 10px;
  }

  .backWhite{
    height: 100%;
    background: white;
    position: absolute;
  }