.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.tableSegment {
    height: calc(100% - 120px);
    display: flex;
    flex-direction: column;
}

.tableWrapper {
    overflow-y: scroll;
}

.userInfo .ui.segment {
    border: 0px !important
}

.textbox {
    margin-top: 10px;
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
}



.textbox textarea {
    background: #FFFFFF;
    border: 1px solid #EEEEEE;
    border-radius: 5px;
    width: 100%;
    min-height: 62px !important;
    height: 62px !important;
    padding: 10px;
    resize: none;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #969696;
}

.noteBox textarea {
    min-height: 70px !important;
    height: 70px !important;
}

.noteBox {
    margin-top: 0 !important;
}

.textbox button {
    background: #21DC77;
    padding: 12px 0;
    border: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
    width: 100%;
    font-family: 'Inter'
}

::-webkit-scrollbar {
    width: 4px;
    /* width of vertical scrollbar */
}